import Hls from 'hls.js'

export const PlayVideo = async (ymd: string, hms: string) => {
    var video = document.getElementById('video') as HTMLMediaElement
    if (Hls.isSupported()) {
        var hls = new Hls({
            //startPosition: 0
        })
        hls.loadSource(`${window.location.origin}/events/${ymd}/${hms}/event.m3u8`)
        hls.attachMedia(video)
        try {
            await video.play()
            return true
        } catch (e) {
            console.log(e.name)
            console.log(e.message)
            // if (e.message == "play() failed because the user didn't interact with the document first.") {
            //     return false
            // }
            return false
        }
    } else {
        // iOS
        video.src = `${window.location.origin}/events/${ymd}/${hms}/event.m3u8`
        try {
            await video.play()
            return true
        } catch {
            return false
        }
    }
}

export const StopVideo = () => {
    var video = document.getElementById('video') as HTMLMediaElement
    if (video) {
        video.pause()
        video.src = ""
    }
}