import { A, Route, Routes, useLocation } from '@solidjs/router'
import Cookies from 'js-cookie'
import { Component, createSignal, onCleanup, onMount } from 'solid-js'

import styles from './App.module.css'
import { LiveRoute } from './routes/LiveRoute'
import { LoginRoute } from './routes/LoginRoute'
import { SavedEventsRoute } from './routes/SavedEventsRoute'
import { SettingsRoute } from './routes/SettingsRoute'

//import logo from './logo.svg'
const App: Component = () => {

    const [cpuTemp, setCPUTemp] = createSignal<number>(null)
    const [gpuTemp, setGPUTemp] = createSignal<number>(null)
    const [mseMinMax, setMseMinMax] = createSignal<[number, number]>(null)
    const [recording, setRecording] = createSignal<boolean>(null)

    const authCookie = Cookies.get("auth")

    var getTempsInterval = null

    const getTemps = () => {
        fetch('/api/rxtx/cpu_temp').then(async res => {
            setCPUTemp(await res.json())
        })
        fetch('/api/rxtx/gpu_temp').then(async res => {
            setGPUTemp(await res.json())
        })
    }
    const getMSEMinMax = () => {
        fetch('/api/rxtx/mse-minmax').then(async res => {
            setMseMinMax(await res.json())
        })
    }
    const getRecording = () => {
        fetch('/api/rxtx/recording').then(async res => {
            setRecording(await res.json())
        })
    }

    onMount(async () => {
        if (useLocation().pathname != "/login") {
            getTempsInterval = setInterval(() => {
                getTemps()
                getMSEMinMax()
                getRecording()
            }, 2500)
            getTemps()
            getMSEMinMax()
            getRecording()
        }
    })

    onCleanup(() => {
        clearInterval(getTempsInterval)
    })

    return (
        <div class={styles.App}>
            <header>
                <div class="px-3 py-2 text-bg-dark">
                    <div class="container">
                        <div class="d-flex flex-wrap align-items-center justify-content-between justify-content-lg-start">
                            <div class="d-flex align-items-center my-0 my-lg-0 me-lg-auto text-white">
                                <i class="bi-camera-video" style="font-size: 2rem;"></i>
                                <div class='d-flex flex-column ms-2 text-secondary'>
                                    <div style='font-size: 0.65rem;' title='CPU Temperature'><i class="bi-cpu me-1"></i>{cpuTemp()}°C</div>
                                    <div style='font-size: 0.65rem;' title='GPU Temperature'><i class="bi-gpu-card me-1"></i>{gpuTemp()}°C</div>
                                    <div style='font-size: 0.65rem;' title='Current Min-Max MSE values'><i class="bi-activity me-1"></i>[{mseMinMax() ? (Math.round(mseMinMax()[0] * 100) / 100) : ""}, {mseMinMax() ? (Math.round(mseMinMax()[1] * 100) / 100) : ""}]</div>
                                    <div style='font-size: 0.65rem;'
                                        title={recording() ? 'Recording' : 'Idle'}
                                        classList={{
                                            "text-danger": recording()
                                        }}
                                    >
                                        {recording() ? <i class="bi-record2 me-1"></i> : <i class="bi-pause-circle me-1"></i>}
                                    </div>
                                </div>
                            </div>
                            <ul class="nav col-auto my-2 justify-content-center my-md-0 text-small text-center">
                                {useLocation().pathname == "/login" ? null :
                                    <>
                                        <li>
                                            <A href="/live" activeClass='text-info' inactiveClass='text-white' class="nav-link">
                                                <i class="bi-eye d-block mx-auto mb-1"></i>
                                                <strong>Live</strong>
                                            </A>
                                        </li>
                                        <li>
                                            <A href="/saved" activeClass='text-info' inactiveClass='text-white' class="nav-link">
                                                <i class="bi-cassette d-block mx-auto mb-1"></i>
                                                <strong>Saved</strong>
                                            </A>
                                        </li>
                                        <li>
                                            <A href="/settings" activeClass='text-info' inactiveClass='text-white' class="nav-link">
                                                <i class="bi-gear d-block mx-auto mb-1"></i>
                                                <strong>Settings</strong>
                                            </A>
                                        </li>
                                    </>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </header>

            <main class="container my-2">
                <Routes>
                    <Route path="/login" component={LoginRoute} />
                    <Route path="/live" component={LiveRoute} />
                    <Route path="/saved/:ymd?/:hms?" component={SavedEventsRoute} />
                    <Route path="/settings" component={SettingsRoute} />
                </Routes>
            </main>

            <footer class="py-3 my-4 px-2 border-top">
                <div class="container d-flex flex-wrap justify-content-between align-items-center ">
                    <div class="col-md-4 d-flex align-items-center">
                        <a href="/" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                            <i class="bi-camera-video d-block mx-auto" style="font-size: 1.5rem;"></i>
                        </a>
                        <span class="mb-3 mb-md-0 text-muted">© 2022 Pi Camera v1.0.0</span>
                    </div>

                    <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
                        <li class="ms-3"><a class="text-muted" href="#"><i class="bi-github d-block mx-auto mb-1"></i></a></li>
                        <li class="ms-3"><a class="text-muted" href="#"><i class="bi-discord d-block mx-auto mb-1"></i></a></li>
                        <li class="ms-3"><a class="text-muted" href="#"><i class="bi-telegram d-block mx-auto mb-1"></i></a></li>
                    </ul>
                </div>
                <div class="container d-flex flex-wrap justify-content-center align-items-center" classList={{ "d-none": authCookie === undefined }}>
                    <a class="link-secondary text-decoration-none" href="/api/logout">Log out</a>
                </div>
            </footer>
        </div>
    )
}

export default App
