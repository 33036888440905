/* @refresh reload */
import 'solid-devtools'
import { render } from 'solid-js/web'

import './index.css'
import App from './App'
import { Router } from '@solidjs/router'
import { StoreProvider } from './stores/store'

render(() => (
    <StoreProvider>
        <Router>
            <App />
        </Router>
    </StoreProvider>
), document.getElementById('root') as HTMLElement)
