import { BeforeLeaveEventArgs, useBeforeLeave } from '@solidjs/router'
import { Component, createEffect, createSignal, onMount } from 'solid-js'

import { PlayVideo, StopVideo } from '../helpers/utils'
import { useStore } from '../stores/store'

export const LiveRoute: Component = () => {

    var liveRefreshInterval = null
    const [footageToPlay, setFootageToPlay] = useStore()
    const [playNeedsButton, setPlayNeedsButton] = createSignal(false)

    onMount(async () => {
        const res = await fetch('/api/live')
        var live_stream: string = (await res.json()).live
        console.log(`Live stream at ${live_stream}`)
        var [_empty, _eventString, ymd, hms] = live_stream.split("/")
        console.log("Setting interval")
        liveRefreshInterval = setInterval(async () => {
            // refresh live view
            await fetch('/api/live')
        }, 5000)
        setFootageToPlay({ymd: ymd, hms: hms})
    })

    createEffect(async () => {
        if (footageToPlay()) {
            console.log(`Request to play ${footageToPlay().ymd}/${footageToPlay().hms} footage`)
            var playRes = await PlayVideo(footageToPlay().ymd, footageToPlay().hms)
            if (!playRes) {
                setPlayNeedsButton(true)
            } else {
                setPlayNeedsButton(false)
            }
        }
    })

    useBeforeLeave((e: BeforeLeaveEventArgs) => {
        if (e.from.pathname == "/live") {
            console.log("Clearing interval")
            clearInterval(liveRefreshInterval)
            StopVideo()
            setFootageToPlay(null)
        }
    })

    return (
        <>
            <div class="row">
                <div class="col-12 d-flex align-items-start">
                    <video id="video" controls playsinline style="width: 100%; aspect-ratio: 16/9;" poster='https://upload.wikimedia.org/wikipedia/commons/thumb/7/72/16x9_by_Pengo.svg/640px-16x9_by_Pengo.svg.png'></video>
                    <button
                        class="btn btn-primary position-absolute top-0 bottom-0 start-0 end-0 fs-1"
                        classList={{ "d-none": !playNeedsButton() }}
                        onclick={() => {
                            var video = document.getElementById('video') as HTMLMediaElement
                            video.play()
                            setPlayNeedsButton(false)
                        }}
                    >
                        <i class="bi-play-btn d-block mx-auto mb-1"></i> Click to play
                    </button>
                </div>
            </div>
        </>
    )
}