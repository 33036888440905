import { A, useParams } from '@solidjs/router'
import { Component, createSignal, For, onMount } from 'solid-js'

import { useStore } from '../stores/store'

//import logo from './logo.svg'
//import styles from './App.module.css'

interface EventsADayComponentProps {
    eventDay: {
        event_date: string,
        event_date_count: number,
    }
}

export const EventsADayComponent: Component<EventsADayComponentProps> = (props) => {
    const [events, setEvents] = createSignal([])

    let btn: HTMLButtonElement = undefined
    let suppressUpdate = false

    const getEventForDay = async (event_day: string, evt: Event) => {
        if (suppressUpdate) { return }
        if (events().length == 0) {
            const res = await (await fetch(`/api/events/${event_day}`)).json()
            setEvents(res)
            if (res.length > 0) {
                suppressUpdate = true
                btn.click()
                suppressUpdate = false
            }
        } else {
            const res = await (await fetch(`/api/events/${event_day}`)).json()
            setEvents(res)
        }
    }

    onMount(() => {
        var params = useParams()
        if (params.ymd && params.ymd == props.eventDay.event_date) {
            getEventForDay(props.eventDay.event_date, null)
        }
    })

    return (
        <>
            <li class="mb-1">
                <button
                    ref={btn}
                    type="button"
                    class="btn btn-toggle d-inline-flex align-items-center rounded border-0 font-monospace"
                    onClick={[getEventForDay, props.eventDay.event_date]}
                    data-bs-toggle={events().length > 0 ? "collapse" : ""}
                    data-bs-target={`#collapse-${props.eventDay.event_date}`}
                    aria-expanded="false"
                >
                    {props.eventDay.event_date}
                    <span class="badge rounded-pill bg-info text-dark ms-2">{events().length ? events().length : props.eventDay.event_date_count}</span>
                </button>
                <div class="collapse" id={`collapse-${props.eventDay.event_date}`}>
                    <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                        <For each={events()} fallback={<li>&nbsp;</li>}>
                            {event => <EventComponent eventDay={props.eventDay} event={event} />}
                        </For>
                    </ul>
                </div>
            </li>
        </>
    )
}

interface EventComponentProps extends EventsADayComponentProps {
    event: {
        event_time: string,
        event_time_count: number,
        inference: any
    }
}

export const EventComponent: Component<EventComponentProps> = (props) => {
    const [footageToPlay, setFootageToPlay] = useStore()
    const playEvent = async (data: EventComponentProps, evt: Event) => {
        setFootageToPlay({
            ymd: props.eventDay.event_date,
            hms: props.event.event_time,
        })
    }
    return (
        <>
            <li>
                <A
                    href={`/saved/${props.eventDay.event_date}/${props.event.event_time}`}
                    activeClass='bg-info'
                    //inactiveClass='text-white'
                    role='button'
                    onclick={[playEvent, { day: props.eventDay.event_date, time: props.event.event_time }]}
                    class="link-dark d-inline-flex text-decoration-none rounded font-monospace"
                >
                    {props.event.event_time.replaceAll("-", ":")}
                    <span class="badge bg-warning text-dark ms-2" style="margin-top: 1px;">
                        {Math.floor(props.event.event_time_count / 60).toString().padStart(2, '0')}:{(props.event.event_time_count % 60).toString().padStart(2, '0')}
                        <i class="bi-person-bounding-box ps-3" classList={{ "d-none": props.event.inference.person === undefined }}> x{props.event.inference.person}</i>
                    </span>
                </A>
            </li>
        </>
    )
}