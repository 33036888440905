import axios from 'axios'
import { Component, createSignal, For, JSX, onMount } from 'solid-js'

export const SettingsRoute: Component = () => {
    const [settings, setSettings] = createSignal({})
    onMount(async () => {
        const res = await (await fetch('/api/settings')).json()
        setSettings(res)
    })

    const handleSettingChange = async (setting, type, value) => {
        console.log(`${setting} changed to ${value}`)
        await axios.post('/api/settings', {
            setting,
            value: type == "number" ? parseFloat(value) : value,
        })
        if (setting == "password") {
            window.location.reload()
        }
        const res = await (await fetch('/api/settings')).json()
        setSettings(res)
    }

    const subheader = (title) => <div class="col-12 fs-5 px-2 py-1 mb-2 rounded bg-dark bg-opacity-25"><b>{title}</b></div>

    const makeSettingDOM = (setting: string, value: any): JSX.Element => {
        var display = `not implemented (${setting})`
        var help = "not implemented"
        var type = undefined
        var min = undefined
        var max = undefined
        var input: JSX.Element = null

        const [pending, setPending] = createSignal(false)

        const handleInputChange = async (e: KeyboardEvent & { currentTarget: HTMLInputElement; target: Element }) => {
            if (e.code == "Enter" || e.code == "NumpadEnter") {
                setPending(true)
                await handleSettingChange(e.currentTarget.id, e.currentTarget.type, e.currentTarget.value)
                setPending(false)
            }
            if (e.currentTarget.type == "password")
                e.currentTarget.type = "text"
        }

        switch (setting) {
            case "enabled":
                display = "App Enabled"
                help = "Global ON/OFF switch for the application. If unchecked, the PI will not record, nor send any footages to the server."
                type = "checkbox"
                break
            case "password":
                display = "Login Password"
                help = "If set to empty, no password is asked."
                type = "password"
                break
            case "hash":
                display = "Direct link hash"
                help = "Hash used to validate direct links. Change this to something unique. If you suspect your notifications are compromised, you can change this hash to invalidate all previous notification direct links."
                type = "password"
                break
            case "retention_period_days":
                display = "Footage retention period (in days)"
                help = "Retention period of security footage, in days. If set to `0`, footages are not deleted. The check is done once per day."
                type = "number"
                min = 0
                max = Infinity
                break
            case "retention_used_disk_space_treshold":
                display = "Footage retention by used disk space (treshold, in megabytes)"
                help = "If set, periodically checks for currently used space. If footages exceed this treshold, the oldest events will be deleted until the used disk space falls below the threshold. If set to `0`, footages are not deleted. The provided value is in `MB` (megabyte). The check is done once per day."
                type = "number"
                min = 0
                max = Infinity
                break
            case "video_resolution":
                display = "Resolution of captured footage"
                help = "Resolution of captured footage"
                input = <select class="form-select" disabled={pending()} id={setting} name={setting} onchange={async (e) => {
                    setPending(true)
                    await handleSettingChange(setting, "select", e.currentTarget.value)
                    setPending(false)
                }}>
                    <option value="[1920,1080]" selected={value == "1920,1080"}>1920 x 1080 (1080p)</option>
                    <option value="[1280,720]" selected={value == "1280,720"}>1280 x 720 (720p)</option>
                    <option value="[854,480]" selected={value == "854,480"}>854 x 480 (480p)</option>
                    <option value="[640,360]" selected={value == "640,360"}>640 x 360 (360p)</option>
                    <option value="[426,240]" selected={value == "426,240"}>426 x 240 (240p)</option>
                </select>
                break
            case "video_quality":
                display = "Video quality (in bps)"
                help = "Sets video quality, in bits per second"
                type = "number"
                min = 1024
                max = Infinity
                break
            case "fps":
                display = "Video FPS"
                help = "Sets video FPS (frames per second)"
                type = "number"
                min = 1
                max = 60
                break
            case "transform":
                display = "Transform captured footage"
                help = "Choose whether to flip the footage horizontally, vertically, or both"
                input = <select class="form-select" disabled={pending()} id={setting} name={setting} onchange={async (e) => {
                    setPending(true)
                    await handleSettingChange(setting, "select", e.currentTarget.value)
                    setPending(false)
                }}>
                    <option value="0" selected={value == "0"}>No transform</option>
                    <option value="1" selected={value == "1"}>Flip horizontally</option>
                    <option value="2" selected={value == "2"}>Flip vertically</option>
                    <option value="3" selected={value == "3"}>Rotate 180 degrees (Flip H+V)</option>
                </select>
                break
            case "motion_treshold":
                display = "Motion treshold"
                help = "Treshold from which PI will consider the footage to have motion. This number is the mean difference between pixels from one frame to the next."
                type = "number"
                min = 0.1
                max = 255
                break
            case "yolo_enabled":
                display = "YOLO v6 nano Enabled"
                help = "Whether to use YOLO v6 nano to determine if there's anything important in the footage or not. Notifications will be sent only on cases when yolo found a person at the start of the footage. If unchecked, the the server will not use YOLO to analyze the footage."
                type = "checkbox"
                break
            case "audio_enabled":
                display = "Record audio"
                help = "Flag to record audio or not. If unchecked, no audio will be recorded."
                type = "checkbox"
                break
            case "audio_quality":
                display = "Audio quality (bps)"
                help = "Quality of the recorded audio, in bits per second"
                type = "number"
                min = 1024
                max = Infinity
                break
            case "audio_sync":
                display = "Audio sync (s)"
                help = "Audio sync time compared to video. Audio will be delayed by this much seconds."
                type = "number"
                min = 1024
                max = Infinity
                break
            case "notification_enabled":
                display = "Notifications enabled"
                help = "Whether notifications are enabled or not. If unchecked, notifications are disabled."
                type = "checkbox"
                break
            case "notify_only_on_objects":
                display = "Notify only on YOLO detected objects"
                help = "If YOLO based detection is enabled, whether notify only when objects are detected, or always. If unset, will notify on all detections, regardless of YOLO findings."
                type = "checkbox"
                break
            case "telegram_notification_enabled":
                display = "Telegram notifications enabled"
                help = "Whether telegram notifications are enabled or not. If unchecked, telegram notifications are disabled."
                type = "checkbox"
                break
            case "telegram_bot_token":
                display = "Telegram bot token"
                help = "Telegram bot token provided by the @BotFather"
                type = "text"
                break
            case "telegram_bot_reg_password":
                display = "Telegram bot password"
                help = "The bot will listen to this password, and if it encounters it anywhere, it will register that chat as a destination to send notifications to."
                type = "text"
                break
            case "telegram_chat_ids":
                display = "Telegram chat IDs"
                help = "Telegram Chat IDs which will receive notifications. Format is number,number,number,..."
                type = "text"
                break
        }

        if (input == null)
            input = <input disabled={pending()} class={type == "checkbox" ? "form-check-input" : "form-control"} type={type} id={setting} name={setting} value={value} min={min} max={max} checked={type == "checkbox" ? value : undefined} onkeypress={handleInputChange} />

        return (
            <div class="row mb-1 pb-1 border-bottom">
                <div class="col-12 col-md-8">
                    <b>{display}</b><br />
                    <small>{help}</small>
                </div>
                <div class="col-12 col-md-3 my-2 my-md-0 d-flex align-items-center justify-content-center">{input}</div>
                <div class="col-12 col-md-1 d-flex align-items-center">
                    <button disabled={pending()} class="btn btn-secondary flex-grow-0 flex-md-grow-1 ms-auto ms-md-0"
                        onclick={async () => {
                            setPending(true)
                            await handleSettingChange(setting, type, type == "checkbox" ? (input as HTMLInputElement).checked : (input as HTMLInputElement).value)
                            setPending(false)
                        }}>
                        <div classList={{ "d-none": pending() }}>Save</div>
                        <div classList={{ "d-none": !pending() }} class="spinner-border spinner-border-sm" role="status"></div>
                    </button>
                </div>
            </div>
        )
    }
    return (
        <>
            <For each={Object.keys(settings())} fallback={<tr><td>Loading...</td></tr>}>
                {(item, idx) => {
                    switch (item) {
                        case "enabled":
                            return (
                                <>
                                    {subheader("Global Settings")}
                                    {makeSettingDOM(item, settings()[item])}
                                </>
                            )
                            break
                        case "retention_period_days":
                            return (
                                <>
                                    {subheader("Video Settings")}
                                    {makeSettingDOM(item, settings()[item])}
                                </>
                            )
                            break
                        case "audio_enabled":
                            return (
                                <>
                                    {subheader("Audio Settings")}
                                    {makeSettingDOM(item, settings()[item])}
                                </>
                            )
                            break
                        case "notification_enabled":
                            return (
                                <>
                                    {subheader("Notification Settings")}
                                    {makeSettingDOM(item, settings()[item])}
                                </>
                            )
                            break

                    }
                    return makeSettingDOM(item, settings()[item])
                }
                }
            </For>
        </>
    )
}

