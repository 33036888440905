import Cookies from 'js-cookie'
import { Component, onMount } from 'solid-js'

export const LoginRoute: Component = () => {

    const error = Cookies.get("error")
    onMount(async () => {
        Cookies.remove("error")
    })

    return (
        <>
            <form action={`/login${window.location.search}`} method="post">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Password</label>
                                <input type="password" class="form-control" id="password" name="password" />
                            </div>
                            <div class="mb-3 d-flex">
                                <div class="text-danger" classList={{ "d-none": (error === undefined) }}><small>Invalid password</small></div>
                                <button type="submit" class="btn btn-primary flex-grow-0 ms-auto">Log in</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}