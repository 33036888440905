import { createSignal, createContext, useContext } from "solid-js"

interface FootageToPlay {
    ymd: string
    hms: string
}

type StoreType = [
    footageToPlay: () => FootageToPlay,
    setFootageToPlay: (val: FootageToPlay) => void,
]

const StoreContext = createContext<StoreType>()

export function StoreProvider(props) {
    const [footageToPlay, setFootageToPlay] = createSignal<FootageToPlay>(null)
    const store: StoreType = [
        footageToPlay, 
        setFootageToPlay
    ]

    return (
        <StoreContext.Provider value={store}>
            {props.children}
        </StoreContext.Provider>
    );
}

export function useStore() { return useContext<StoreType>(StoreContext); }