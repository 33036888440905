import { useBeforeLeave, useParams } from '@solidjs/router'
import { Component, createEffect, createSignal, For, onMount } from 'solid-js'

import { EventsADayComponent } from '../components/EventComponents'
import { PlayVideo, StopVideo } from '../helpers/utils'
import { useStore } from '../stores/store'

export const SavedEventsRoute: Component = () => {
    const [events, setEvents] = createSignal([])
    const [playNeedsButton, setPlayNeedsButton] = createSignal(false)
    const [footageToPlay, setFootageToPlay] = useStore()
    onMount(async () => {
        var params = useParams()
        if (params.ymd && params.hms) {
            setFootageToPlay({
                ymd: params.ymd,
                hms: params.hms
            })
        }
        const res = await fetch('/api/events')
        setEvents(await res.json())
    })

    createEffect(async () => {
        if (footageToPlay()) {
            console.log(`Request to play ${footageToPlay().ymd}/${footageToPlay().hms} footage`)
            var playRes = await PlayVideo(footageToPlay().ymd, footageToPlay().hms)
            if (!playRes) {
                setPlayNeedsButton(true)
            } else {
                setPlayNeedsButton(false)
            }
        }
    })

    useBeforeLeave((e) => {
        if (e.from.pathname.indexOf("/saved") > -1 && e.to.toString().indexOf("/saved") == -1) {
            StopVideo()
            setFootageToPlay(null)
        }
    })

    return (
        <>
            <div class="row">
                <div class="col-12 col-md-10 d-flex align-items-start position-relative">
                    <video id="video" controls playsinline style="width: 100%; aspect-ratio: 16/9;" poster='https://i.ytimg.com/vi/IIqtuupvdWg/maxresdefault.jpg'></video>
                    <button
                        class="btn btn-primary position-absolute top-0 bottom-0 start-0 end-0 fs-1"
                        classList={{ "d-none": !playNeedsButton() }}
                        onclick={() => {
                            var video = document.getElementById('video') as HTMLMediaElement
                            video.play()
                            setPlayNeedsButton(false)
                        }}
                    >
                        <i class="bi-play-btn d-block mx-auto mb-1"></i> Click to play
                    </button>
                </div>
                <div class="col-12 mt-2 mt-md-0 col-md-2">
                    <ul class="list-unstyled ps-0">
                        <For each={events()}>
                            {event => <EventsADayComponent eventDay={event} />}
                        </For>
                    </ul>
                </div>
            </div>
        </>
    )
}